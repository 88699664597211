<template>
  <div
    class="challenge-banner relative w-full sm:h-[156px] h-auto sm:py-[12px] sm:pl-[48px] p-[24px] border border-primary_800 flex sm:bg-green-gradient bg-mobile-green-gradient items-center sm:justify-between justify-around cursor-pointer"
    :class="onNewsArticle ? '' : 'mb-[16px]'"
    @click="handleBannerClick"
  >
    <div class="challenge-logo">
      <img
        class="sm:h-[128px] h-[96px]"
        src="/assets/img/logoProfesorsChallange.png"
        alt="Professors challenge logo"
      />
    </div>
    <div class="challenge-text">
      <img
        v-if="locale === 'sr'"
        src="/assets/img/challenge-banner-text-sr.svg"
        alt="banner text"
        class="sm:w-[231px] w-[150px]"
      />
      <img
        v-else
        src="/assets/img/challenge-banner-text-en.svg"
        alt="banner text"
        class="sm:w-[231px] w-[150px]"
      />
    </div>
    <div
      class="challenge-action sm:h-[128px] sm:w-[181px] items-center justify-center sm:border-l border-[#354D14] hidden sm:flex"
    >
      <ButtonComponent :size="commonStore.isMobile ? 'sm' : 'md'" type="outlined">
        {{ t('aboutChallenge') }}
      </ButtonComponent>
    </div>
    <div v-if="!props.onNewsArticle" class="absolute top-2 right-2">
      <ClientOnly>
        <ion-icon
          class="text-base_white text-[24px]"
          name="close-outline"
          @click="handleCloseClicked"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCommonStore } from '~/store/commonStore';
const { t, locale } = useI18n();
const { saveValue: hideChallengeBanner } = useTmpLocalStorage<boolean>('hideChallengeBanner');

const commonStore = useCommonStore();
const localePath = useLocalePath();

const emits = defineEmits(['close-banner-for-now']);
const router = useRouter();

const props = defineProps({
  onNewsArticle: {
    type: Boolean,
    default: false
  }
});

const handleBannerClick = () => {
  router.push(localePath('/professors-challenge'));
  if (props.onNewsArticle) {
    useTrackEvent('professors-banner-click', {
      place: 'News article'
    });
  }
};

const handleCloseClicked = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
  hideChallengeBanner(true);
  emits('close-banner-for-now');
};
</script>
